import React, { useEffect, useState } from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import "./text-img-section.scss";

interface ImgDto {
  img: string;
  imgMaxWidth?: number;
  imgAlt?: string;
}

interface Props {
  imgs: Array<ImgDto>;
  children: React.ReactNode;
  isReversed?: boolean;
  isMobileReversed?: boolean;
  className?: string;
}

const TextImgSection: React.FC<Props> = (props: Props) => {
  const { isReversed, children, className, isMobileReversed, imgs } = props;
  const [imgIndex, setImgIndex] = useState(0)

  useEffect(() => {
    if (imgs && imgs.length > 1) {
      setInterval(() => {
        setImgIndex((state) => (state + 1))
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (imgIndex >= imgs.length) setImgIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgIndex])


  return (
    <section className={`text-img-section${className ? " " + className : ""}`}>
      <Container>
        <Grid>
          <Row className={`${isReversed ? " reversed" : ""}${isMobileReversed ? " isMobileReversed" : ""}`}>
            <Col size={[6, 4, 4, 12]} className="imgCol">
              {imgs && imgs.length && imgs.length > 0 && imgs.length > imgIndex ?
                <img src={imgs[imgIndex].img} alt={imgs[imgIndex].imgAlt ? imgs[imgIndex].imgAlt : "img"} style={{ maxWidth: imgs[imgIndex].imgMaxWidth }} />
                : null}
            </Col>
            <Col size={[6, 8, 8, 12]}>
              {children}
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>

  )
}

export default TextImgSection;