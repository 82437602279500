import React from "react";
import FtsArturGrzybImg from "./../../assets/fts-artur-grzyb.svg";
import FtsBetonImg from "./../../assets/FTS-Beton-logo.svg";
import CzasNaDesignImg from "./../../assets/czas-nas-design.svg";
import StandardTextBlock from "../standard-text-block/standard-text-block";

const LogosCol: React.FC = () => {
  return (
    <div className="logos">
      <StandardTextBlock>
        <p className="title">NA SKRÓTY</p>
        <div className="logosWrapper">
          <a href="https://www.ftsgrzyb.pl/fts-artur-grzyb" target="_blank" rel="noopener noreferrer">
            <img src={FtsArturGrzybImg} alt="FTS Artur Grzyb" />
          </a>
          <a href="https://www.ftsgrzyb.pl/fts-beton" target="_blank" rel="noopener noreferrer">
            <img src={FtsBetonImg} alt="FTS Beton" />
          </a>
        </div>
        <div className="logosWrapper">
          <a href="https://www.czasnadesign.pl" target="_blank" rel="noopener noreferrer">
            <img src={CzasNaDesignImg} alt="Czas na Design Angelika Grzyb" />
          </a>
        </div>
      </StandardTextBlock>
    </div>
  )
}

export default LogosCol;