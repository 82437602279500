import "./home-page.scss";
import React, { ReactNode } from "react";

import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import FrameComp from "./../../assets/piwios-25-lat-logo.png";
import FrameDev from "./../../assets/PIWOS-deweloper-logo.png";


interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode
}

const HomePage: React.FC<Props> = () => {

  return (
    <>
      <div className={`home-page`}>
        <div className="home-page-row">
          <div className="side side--left">
            <TopLink to={RoutePath.COMPANY}>
              <img src={FrameComp} alt="Piwioś" />
            </TopLink>
          </div>
          <div className="side side--right">
            <TopLink to={RoutePath.DEVELOPER}>
              <img src={FrameDev} alt="Piwioś" />
            </TopLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage;