import React, { ReactNode } from "react";
import ContactSection from "../../../components/contact-section/contact-section";
import FullImgSection from "../../../components/full-img-section/full-img-section";
import HeroSectionDev from "../../../components/hero-section-dev/hero-section-dev";
import HeroTilesSectionDev from "../../../components/heroTiles-section-dev/heroTiles-section-dev";
import HeroTilesSectionDevTwo from "../../../components/heroTiles-section-dev-two/heroTiles-section-dev-two";
import MainHeaderDev from "../../../components/main-header/main-header-dev";
import OfertaSectionDev from "../../../components/oferta-section/oferta-section-dev";
import StandardTextBlock from "../../../components/standard-text-block/standard-text-block";
import TextImgSection from "../../../components/text-img-section/text-img-section";
import { scrollToById } from "../../../tools/handleScrollToSection";
import LigotaImg from "./../../../assets/ligota-wielka.png";
import BigLogoImg from "./../../../assets/piwiosDevBig.png";
import BgImg from "./../../../assets/piwios-deweloper-25-lat-na-rynku.png";
import "./developer-page.scss";
import MainBgA from "./../../../assets/dev/investitions/zacisze-wschodnie-namyslow.png";
import MainBgB from "./../../../assets/dev/investitions/dom-jednorodzinny-namyslow.png";
import MainBgC from "./../../../assets/dev/investitions/dom-w-zabudowie-szeregowej-namyslow.png";
import TopLink from "../../../components/top-link/top-link";
import { RoutePath } from "../../../../route-paths";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode;
}

const DeveloperPage: React.FC<Props> = () => {

  return (
    <>
      <div className={`home-page home-page-dev`}>
      <HeroSectionDev bgs={[MainBgA, MainBgB, MainBgC]} className="smaller_padding_top">
          <div className="label">
            <strong>OSIEDLE</strong>
          </div>
          <h2>
            <strong>ZACISZE WSCHODNIE</strong>
          </h2>
          <div className="break"></div>
          <div className="label">już od</div>
          <h2>
            <strong>585 000 zł</strong>
          </h2>
          <div className="break"></div>
          <h2> 133 m&#178;</h2>
          <StandardTextBlock>
            <TopLink to={RoutePath.DEVELOPER_INVESTITION}>
                    <button className="btn btn-dev-outline">DOWIEDZ SIĘ WIĘCEJ</button>
                  </TopLink>
          </StandardTextBlock>
        </HeroSectionDev>
        <HeroTilesSectionDev />
        <HeroSectionDev bgs={[LigotaImg]}>
          <div className="label">
            <strong>LIGOTA WIELKA</strong>
          </div>
          <h2>
            <strong>SPOKOJNY ZAKĄTEK</strong>
          </h2>
          <div className="break"></div>
          <div className="label">Więcej informacji
            <h2>
              <strong>JUŻ WKRÓTCE</strong>
            </h2></div>
          <div className="break"></div>
          <h2>111 m&#178;</h2>
          <StandardTextBlock>
            <button
              className="btn btn-dev-outline"
              onClick={() => scrollToById("contactform", 96, true)}
            >
              ZAPYTAJ O OFERTĘ
            </button>
          </StandardTextBlock>
        </HeroSectionDev>
        <HeroTilesSectionDevTwo />
        <FullImgSection bg={BgImg}>
          Ponad 25 lat doświadczenia w dostarczaniu nowoczesnych rozwiązań
        </FullImgSection>
        <TextImgSection
          imgs={[
            {
              img: BigLogoImg
            },
          ]}
          className="smallImgOnMobile"
        >
          <MainHeaderDev>O NAS</MainHeaderDev>
          <StandardTextBlock>
            <p>
              <strong>
                Przedsiębiorstwo Inżynierii Wodnej i Ochrony Środowiska
              </strong>
            </p>
            <br />
            <p>
              Od ponad 25 lat dostarczamy rozwiązania na rynku Inżynierii Wodnej i Ochrony Środowiska. W związku z nabytym doświadczeniem z zakresu wszelakich robót budowlanych oraz kanalizacyjnych postanowiliśmy rozpocząć działania Deweloperskie, które polepsza jakość życia ludzi mieszkających w naszych inwestycjach.
            </p>
            <br />
            <p>
              Nasza filozofia pozwala nam na stosowanie coraz to nowszych rozwiązań technologicznych w budowanych przez nas inwestycjach. Polegamy na własnym sprzęcie i zasobach ludzkich, dlatego możemy się pochwalić, że każda inwestycja jest realizowana z największą dbałością o szczegóły.
            </p>
          </StandardTextBlock>
        </TextImgSection>
        <OfertaSectionDev />
        <ContactSection />

        {/* <HistorySectionDev />
        <OfertaSectionDev />
        <ReferencesSectionDev /> */}
      </div>
    </>
  );
};

export default DeveloperPage;
