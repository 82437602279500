import React from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import HeroTileDev from "../hero-tile-dev/hero-tile-dev";
import "./heroTiles-section-dev.scss";
import Bg from "./../../assets/wiz.jpg";
import StandardTextBlock from "../standard-text-block/standard-text-block";
import TopLink from "../top-link/top-link";
import { RoutePath } from "../../../route-paths";
import MainBgA from "./../../assets/dev/investitions/kup-dom-w-namyslowie.png";
import MainBgB from "./../../assets/dev/investitions/wprowadz-sie-do-namyslowa.png";
import MainBgC from "./../../assets/dev/investitions/osiedle-domow-w-zabudowie-szeregowej-namyslow.png";

interface Props {}

const HeroTilesSectionDev: React.FC<Props> = (props: Props) => {
  return (
    <section className="heroTiles-section-dev">
      <Container>
        <Grid>
          <Row gap={16}>
            <Col size={4}>
              <HeroTileDev bg={MainBgA} title="ZACISZE WSCHDNIE">
                <div className="label">
                  <strong>OSIEDLE</strong>
                </div>
                <h3>
                  <strong>ZACISZE WSCHODNIE</strong>
                </h3>
                <div className="labelLong">Namysłów | ul. Wschodnia</div>
                <ul>
                  <li>9 segmentów</li>
                  <li>Własny garaż</li>
                  <li>Własny kawałek ziemi</li>
                  <li>Spokojne osiedle</li>
                  <li>Parter i Piętro</li>
                  <li>Lukarna</li>
                  <li>Własny balkon</li>
                </ul>
                <StandardTextBlock>
                  <TopLink to={RoutePath.DEVELOPER_INVESTITION}>
                    <button className="btn btn-dev-outline">Dowiedz się więcej</button>
                  </TopLink>
                </StandardTextBlock>
              </HeroTileDev>
            </Col>
            <Col size={4}>
              <HeroTileDev bg={MainBgB} title="ROZKŁAD">
                <div className="label">
                  <strong>Segmenty</strong>
                </div>
                <h3>
                  <strong>ROZKŁAD POMIESZCZEŃ</strong>
                </h3>
                <div className="labelLong">Namysłów | ul. Wschodnia</div>
                <ul>
                  <li>Salon z aneksem kuchennym i wyjściem do ogrodu</li>
                  <li>Wiatrołap z miejscem na pomieszczenie gospodarcze</li>
                  <li>Wyjście do garażu</li>
                  <li>Komunikacja w postaci holu</li>
                  <li>Przestronna łazienka</li>
                  <li>Dwa pokoje na górze</li>
                  <li>Mały hol i wspólna łazienka</li>
                  <li>Sypialnia z własną łazienką i pomieszczeniem na garderobę</li>
                </ul>
                <StandardTextBlock>
                  <TopLink to={RoutePath.DEVELOPER_INVESTITION}>
                    <button className="btn btn-dev-outline">Dowiedz się więcej</button>
                  </TopLink>
                </StandardTextBlock>
              </HeroTileDev>
            </Col>
            <Col size={4}>
              <HeroTileDev bg={MainBgC} title="UDOGODNIENIA">
                <div className="label">
                  <strong>OSIEDLE</strong>
                </div>
                <h3>
                  <strong>UDOGODNIENIA</strong>
                </h3>
                <div className="labelLong">Namysłów | ul. Wschodnia</div>
                <ul>
                  <li>Niedaleko plac zabaw należący do osiedla</li>
                  <li>5 minut do Centrum Miasta</li>
                  <li>2 minuty do najbliższego marketu</li>
                  <li>5 minut do najbliższej szkoły</li>
                  <li>7 minut do Rynku w Namysłowie</li>
                  <li>5 minut do trasy na Kępno</li>
                  <li>W okolicy różne stacje benzynowe</li>
                </ul>
                <StandardTextBlock>
                  <TopLink to={RoutePath.DEVELOPER_INVESTITION}>
                    <button className="btn btn-dev-outline">Dowiedz się więcej</button>
                  </TopLink>
                </StandardTextBlock>
              </HeroTileDev>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default HeroTilesSectionDev;
