import React, { useEffect, useState } from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import "./hero-section-dev.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  bgs: Array<string>;
  bgSize?: 9 | 8;
  isReverse?: boolean;
  noPt?: boolean;
  lightGradient?: boolean;

}

const HeroSectionDev: React.FC<Props> = (props: Props) => {
  const { isReverse, noPt, children, bgs, bgSize, lightGradient, className } = props;

  const [imgIndex, setImgIndex] = useState(0)

  useEffect(() => {
    if (bgs && bgs.length > 1) {
      setInterval(() => {
        setImgIndex((state) => (state + 1))
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (imgIndex >= bgs.length) setImgIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgIndex])


  return (
    <section className={`hero-section-dev${noPt ? " no-pt" : ""}${lightGradient ? " lightGradient" : ""}${className ? " " + className : ""}`}>
      <Container>
        <Grid>
          <Row gap={16} className={`${isReverse ? " reversed" : ""}`}>
            <Col size={[(bgSize ? bgSize : 9), 8, 8, 12]} className="imgCol">
              <div className="bgBox" style={{ backgroundImage: `url("${bgs.length > imgIndex ? bgs[imgIndex] : ""}")` }}></div>
            </Col>
            <Col size={[(bgSize ? 4 : 3), 4, 4, 12]} className="contentCol">
              <div className="contentBox">
                {children}
              </div>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  )
}

export default HeroSectionDev;