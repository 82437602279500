import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import PreFooterDev from "../../components/prefooter/prefooterDev";
import { select_is_navbarOpenActive } from "../../redux/callbacksState/callbacksState.selectors";
import DeveloperInvestitionPage from "./developer-investition-page/developer-investition-page";
import DeveloperPage from "./developer-page/developer-page";
import RulesPage from "../home-page/rules-page";

const DeveloperSwitch: React.FC = () => {
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  return (
    <>
      <Navbar isDev={true} />
      <div className={`overlay${is_navbarOpenActive ? " isActive" : ""}`}>
        <Switch>
          <Route exact path={RoutePath.DEVELOPER_INVESTITION} render={() => <DeveloperInvestitionPage />} />
          <Route exact path={RoutePath.COMPANY_RULES} render={() => <RulesPage />} />
          <Route exact path={RoutePath.DEVELOPER} render={() => <DeveloperPage />} />
        </Switch>
        <PreFooterDev />
        <Footer />
      </div>
    </>
  )
}

export default DeveloperSwitch;