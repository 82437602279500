import React, { ReactNode } from "react";
import ExampleImg1 from "./../../../assets/dev/investitions/zacisze-wschodnie-parter.png";
import ExampleBg from "./../../../assets/dev/investitions/2.png";
import ExampleMobileImg from "./../../../assets/dev/investitions/1.mobile.png";
import ExampleMobileBg from "./../../../assets/dev/investitions/2.mobile.png";
import { scrollToById } from "../../../tools/handleScrollToSection";
import HeroSectionDev from "../../../components/hero-section-dev/hero-section-dev";
import StandardTextBlock from "../../../components/standard-text-block/standard-text-block";
import TextImgSection from "../../../components/text-img-section/text-img-section";
import ContactSection from "../../../components/contact-section/contact-section";
import MainHeaderDev from "../../../components/main-header/main-header-dev";
import FullImgBlock from "../../../components/fullImg-block/fullImg-block";
import Container from "../../../components/container/container";
import useWindowSize from "../../../tools/useWindowSize";
import MainBgA from "./../../../assets/dev/investitions/zacisze-wschodnie-namyslow.png";
import MainBgB from "./../../../assets/dev/investitions/dom-jednorodzinny-namyslow.png";
import MainBgC from "./../../../assets/dev/investitions/dom-w-zabudowie-szeregowej-namyslow.png";
import MainBgD from "./../../../assets/dev/investitions/zacisze-wschodnie-balkon-w-domu-jednorodzinnym.png";
import MainBgE from "./../../../assets/dev/investitions/zacisze-wschodnie-ogrzewanie-podlogowe.png";
import MainBgF from "./../../../assets/dev/investitions/zacisze-wschodnie-standard-wyposazenia.png";
import MainBgG from "./../../../assets/dev/investitions/dom-drewniane-schody.png";
import MainBgH from "./../../../assets/dev/investitions/zacisze-wschodnie-dom-z-garazem.png";

interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode
}

const DeveloperInvestitionPage: React.FC<Props> = () => {
  const size = useWindowSize();

  return (
    <>
      <div className={`home-page home-page-dev`}>
        <HeroSectionDev bgs={[MainBgA, MainBgC, MainBgD]} className="pb-5">
          <div className="label"><strong>OSIEDLE</strong></div>
          <h2><strong>ZACISZE WSCHODNIE</strong></h2>
          <div className="break"></div>
          <div className="label">już od</div>
          <h2><strong>585 000 zł</strong></h2>
          <div className="break"></div>
          <h2>133 m&#178;</h2>
          <StandardTextBlock>
            <button
              className="btn btn-dev-outline"
              onClick={() => scrollToById("contactform", 96, true)}
            >
              ZAPYTAJ O OFERTĘ
            </button>
            <a href="https://www.olx.pl/d/oferta/dom-jednorodzinny-w-zabudowie-szeregowej-CID3-IDOhmEF.html"><button
              className="btn btn-dev-outline"
            >
              OLX
            </button></a>
          </StandardTextBlock>
        </HeroSectionDev>
        <HeroSectionDev bgs={[MainBgG]}  bgSize={8} isReverse noPt lightGradient className="pb-5">
          <StandardTextBlock className="white infoBox">
            <div className="center">
              <div className="label"><strong>UDOGODNIENIA</strong></div>
              <div className="title">KAŻDEGO SEGMENTU</div>
              <p>Standard wyposażenia</p>
            </div>
            <div>
              <ul>
                <li>Oświetlenie zewnętrzne</li>
                <li>Pełne okablowanie każdego segmentu, między innymi: satelity, anteny, przewody komunikacyjne</li>
                <li>Schody drewniane na stelażu metalowym malowanym proszkowo z balustradą</li>
                <li>W łazienkach zabudowane geberity firmy TECE</li>
                <li>Zautomatyzowane ogrzewanie podłogowe</li>
                <li>Elektryczne rolety</li>
                <li>W salonie drzwi typu HS, przesuwane</li>
                <li>Na tarasie płytki o rozmiarze 50x60 cm</li>
                <li>Na balkonie płytki o rozmiarze 60x60cm</li>
                <li>Szklane barierki na balkonie</li>
                <li>Parapety z konglomeratu w każdym pokoju, przy każdym oknie</li>
                <li>Betonowa posadzka w garażu</li>
              </ul>
            </div>
          </StandardTextBlock>
        </HeroSectionDev>
        <TextImgSection
          imgs={[
            {
              img: size.isMobile ? ExampleMobileImg : ExampleImg1,
              imgMaxWidth: 800
            }
          ]}
          isReversed
          isMobileReversed
        >
          <MainHeaderDev>PARTER</MainHeaderDev>
          <StandardTextBlock>
            <p>
              <strong>Przestrony dom w zabudowie szeregowej</strong>
            </p>
            <br />
            <p>
              Na parterze segmentu przy skrzyżowaniu ulicy Wileńskiej i ulicy Wschodniej znajdą Państwo wejście do segmentu, które prowadzi wprost do przestronnego wiatrołapu, który może zostać wykorzystany jako pomieszczenie gospodarcze. Dodatkowo znajdą Państwo tutaj drzwi do garażu. Garaż jest w stanie pomieścić jeden samochód osobowy. Nieduży hol dostarcza komunikacji pomiędzy wiatrołapem, salonem, łazienką oraz schodami prowadzącymi na pierwsze piętro.
            </p>
            <br />
            <p>
              Najbardziej charakterystycznym pomieszczeniem na parterze jest z całą pewnością salon, który jest równoczesnie największym pomieszczeniem segmentu. Duże przeszklenie zapewnia niezapomniany widok na ogród, a ilośc miejsca w salonie pozwala na komfortowe umieszczenie w nim kompletu wypoczynkowego i stołu jadalnianego wraz z krzesłami. W narożniku salonu znaduje się aneks kuchenny, który z całą pewnością jest w stanie pomieścić najważniejsze sprzęty AGD, takie jak lodówka, płyta indukcyjna lub gazowa, piekarnik, zmywarka, mikrofala.
            </p>
          </StandardTextBlock>
        </TextImgSection>
        <TextImgSection
          imgs={[
            {
              img: size.isMobile ? ExampleMobileImg : ExampleImg1,
              imgMaxWidth: 800
            }
          ]}
          isReversed
          isMobileReversed
        >
          <MainHeaderDev>I PIĘTRO</MainHeaderDev>
          <StandardTextBlock>
            <p>
              <strong>Piętrowy dom z użytecznym poddaszem w zabudowie szeregowej</strong>
            </p>
            <br />
            <p>
              Po wejściu po schodach na piętro naszego budynku znajdą Państwo nieduży hol, który pełni role komunikacji między pokojami i wspólną łazienką. Na tym piętrze znajdują się dwa niemalże identycznej wielkości pokoje z widokiem na ogród.
            </p>
            <br />
            <p>
              Jendakże najważniejszym pomieszczeniem na piętrze jest tak zwany "master bedroom", czyli innymi mówiąc główna sypialnia. Została ona zaprojektowana tak, aby miała bezpośredni dostęp do jednej z łazienek oraz własnym pomieszczeniem, które może zostać zaadaptowane na garderobę. Sypialnia z całą pewnością jest w stanie pomieścić duże łózko dwuosobowe. Kolejnym atutem głównej sypialni jest dostęp do balkonu, który jest położony od strony ulicy Wschodniej i zapewnia widok na osiedle.
            </p>
          </StandardTextBlock>
        </TextImgSection>
        <section className="pt-5">
          <Container>
            <FullImgBlock img={size.isMobile ? ExampleMobileBg : ExampleBg} />
          </Container>
        </section>
        <HeroSectionDev bgs={[MainBgE, MainBgF]} bgSize={8} lightGradient>
          <StandardTextBlock className="white infoBox">
            <div className="center">
              <div className="label"><strong>OGRZEWANIE</strong></div>
              <div className="title">ZACISZE WSCHODNIE</div>
              <p>Namysłów | ul. Wschodnia</p>
            </div>
            <div>
              <p>
                W całym segmencie zostało położone ogrzewanie podłogowe, które pozwoli na swobodne wykorzystanie całego potencjału pomieszczeń. Ogrzewanie gazowe wraz z piecem i czujnikiem zapewni komfortowe i energooszczędne, a co za tym idzie ekonomiczne rachunki.
              </p>
              <br />
              <p>
                Całość budynku została odpowiednio zaizolowana i ocieplona w celu jak najmniejszego zużycia energi. Dzięki temu będą Państwo pewni, że nawet w najzimniejsze dni nie odczujecie chłodu w swoim nowym domu.
              </p>
            </div>
          </StandardTextBlock>
        </HeroSectionDev>
        <HeroSectionDev bgs={[MainBgB]}  bgSize={8} isReverse noPt lightGradient className="pb-5">
          <StandardTextBlock className="white infoBox">
            <div className="center">
              <div className="label"><strong>REKREACJA</strong></div>
              <div className="title">ZACISZE WSCHODNIE</div>
              <p>Namysłów | ul. Wschodnia</p>
            </div>
            <div>
              <p>
                Wybudowane przez nas osiedle jest położone na skrzyżowaniu ulicy Wschodniej z ulicą Wileńską. Położenie inwestycji nie jest przypadkowe, ponieważ została ona zaplanowana na nowo powstającym osiedlu domków jednorodzinnych, który zapewni spokój każdej rodzinie. W niedalekiej odległości jest wybudowany osiedlowy plac zabaw, który zapewni zabawę dla dzieci na długie godziny. Położenie pozwala też na bardzo szybkie dotarcie do centrum (ok. 5 minut piechotą), szkoły podstawowe i liceum (ok. 5 minut), w okolicy znajduje się sklep spożuczwczy (ok 2 minut piechotą), a także sklep remenotwy. Rynek w Namysłowie znajduje się w okolicy 7 minut piechotą od inwestycji, ponadto znajdą Państwo różne stacje benzynowe i zakłady pracy.
              </p>
              <br />
              <p>
                W celach rekreacyjnych można się wybrać do położonego niedaleko jeziora Michalickiego. Trasa na Kępno znajduje się w odległości około 5 minut samochodem, a drogą nr 39 dojada Państwo do Opola. Położona niedaleko droga nr 42 zaprowadzi Państwa do Kluczborka, a jadąc w kierunku Oleśnicy drogą numer 451 dojedzie się spokojnie do Wrocalwia.
              </p>
            </div>
          </StandardTextBlock>
        </HeroSectionDev>
        <ContactSection />
      </div>
    </>
  )
}

export default DeveloperInvestitionPage;