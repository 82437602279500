import React from "react";
import Container from "../container/container";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import HeroTileDevTwo from "../hero-tile-dev-two/hero-tile-dev-two";
import "./heroTiles-section-dev-two.scss";
import StandardTextBlock from "../standard-text-block/standard-text-block";
import Bg1 from "./../../assets/spokojny-zakatek-1.png";
import Bg2 from "./../../assets/spokojny-zakatek-2.png";
import Bg3 from "./../../assets/spokojny-zakatek-3.png";


interface Props {}

const HeroTilesSectionDevTwo: React.FC<Props> = (props: Props) => {
  return (
    <section className="heroTiles-section-dev-two">
      <Container>
        <Grid>
          <Row gap={16}>
            <Col size={4}>
              <HeroTileDevTwo bg={Bg3} title="JEDNORODZINNY">
                <div className="label">
                  <strong>DOM</strong>
                </div>
                <h3>
                  <strong>JEDNORODZINNY</strong>
                </h3> 
                <div className="labelLong"></div>
                <ul>
                  <li>Osiedle Domów Jednorodzinnych, Wolnostojących</li>
                  <li>Metraż działki zależny od metrażu przynależnej działki do domu</li>
                  <li>Zewnetrzne oświetlenie</li>
                </ul>
                <StandardTextBlock>
                  {/* <TopLink to={RoutePath.DEVELOPER_INVESTITION}>
                    <button className="btn btn-dev-outline">Dowiedz się więcej</button>
                  </TopLink> */}
                </StandardTextBlock>
              </HeroTileDevTwo>
            </Col>
            <Col size={4}>
              <HeroTileDevTwo bg={Bg1} title="JEDNORODZINNE">
                <div className="label">
                  <strong>9 DOMÓW</strong>
                </div>
                <h3>
                  <strong>Jednorodzinnych</strong>
                </h3>
                <div className="labelLong"></div>
                <ul>
                  <li>Dobrze rozplanowane pomieszczenia</li>
                  <li>Pompa ciepła w każdym domu</li>
                  <li>Własny kawałek działki</li>
                </ul>
                <StandardTextBlock>
                  {/* <TopLink to={RoutePath.DEVELOPER_INVESTITION}>
                    <button className="btn btn-dev-outline">Dowiedz się więcej</button>
                  </TopLink> */}
                </StandardTextBlock>
              </HeroTileDevTwo>
            </Col>
            <Col size={4}>
              <HeroTileDevTwo bg={Bg2} title="Z OGRODEM">
                <div className="label">
                  <strong>DOM JEDNORODZINNY</strong>
                </div>
                <h3>
                  <strong>Z OGRODEM</strong>
                </h3>
                <div className="labelLong"></div>
                <ul>
                  <li>Spokojna okolica</li>
                  <li>Nieopodal las</li>
                  <li>Własny kawałek zieleni na podwórku</li>
                  <li>Do zagospodarowania</li>
                  <li>Niezależna droga wewnętrzna</li>
                </ul>
                <StandardTextBlock>
                  {/* <TopLink to={RoutePath.DEVELOPER_INVESTITION}>
                    <button className="btn btn-dev-outline">Dowiedz się więcej</button>
                  </TopLink> */}
                </StandardTextBlock>
              </HeroTileDevTwo>
            </Col>
          </Row>
        </Grid>
      </Container>
    </section>
  );
};

export default HeroTilesSectionDevTwo;
