import React from "react";
import "./hero-tile-dev-two.scss";

interface Props {
  children: React.ReactNode;
  title: string;
  bg: string;
}

const HeroTileDev: React.FC<Props> = (props: Props) => {
  const { children, title, bg } = props;
  return (
    <div className="hero-tile-dev" style={{ backgroundImage: `url("${bg}")` }}>
      <div className="hiddenOverlay">
        {children}
      </div>
      <div className="orangeBelt">
        <div className="label"><strong>DOM</strong></div>
        <h3><strong>{title}</strong></h3>
      </div>
    </div>
  )
}

export default HeroTileDev;